<template>
  <div id="home">
    <div class="header_wrap">
      <div class="main">
        <div class="header" v-if="showHeader">
          <div class="top_header">
            <div class="wrap">
              <div>
                您好，欢迎来到{{ $store.state.tenantInfo.company
                }}<router-link to="login" v-if="!$store.state.isLogin">，请先注册或登录会员！</router-link>
              </div>
              <ul class="top_right">
                <li @click="$router.push('/member/inviter')">邀请好友</li>
                <li @click="openQQ()">在线客服</li>
                <li @click="$router.push('/member/guide')">代发指南</li>
                <li @click="$router.push('/news')">帮助中心</li>
              </ul>
            </div>
          </div>
          <div class="center_header wrap">
            <div class="logo fl">
              <router-link to="/"><img style="width: 200px; height: auto; margin-top: 10px"
                  :src="$store.state.tenantInfo.logo | fullPath" alt="孤云网专业礼品代发平台" /></router-link>
            </div>
            <div class="nav fl">
              <ul>
                <router-link tag="li" to="/index">首页</router-link>
                <router-link tag="li" to="/goods/goodsList">礼品商城</router-link>
                <router-link tag="li" to="/member/index">会员中心</router-link>
                <router-link tag="li" to="/member/vip">收费标准</router-link>
                <router-link tag="li" to="/member/guide">代发指南</router-link>

                <router-link tag="li" to="/about" v-if="aboutShow == 2">关于我们</router-link>
                <li tag="li" @click="openQQ()">在线客服</li>
              </ul>
            </div>
            <div class="head_menu_user fl" style="float: right">
              <img class="fl" width="25px" height="25px" src="@/assets/img/user.png" alt="" />
              <div class="info fl" v-if="$store.state.isLogin">
                <span>欢迎您:</span>
                <span class="red" v-if="$store.state.userInfo.info" style="cursor: pointer; font-weight: 400"
                  @click="$router.push('/member/index')">{{ $store.state.userInfo.info.phone }}</span>
                <span class="logout" @click="logout">
                  <i class="el-icon-switch-button"></i>
                  退出账户
                </span>
              </div>
              <div class="info fl" v-else>
                <router-link class="red" to="/login">登录</router-link>
                <span> / </span>
                <router-link to="/register">注册</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="login_header wrap clearfix" v-else>
          <div class="login_img fl">
            <div @click="$router.push('/')">
              <img style="width: 200px; height: auto; margin-top: 10px" :src="$store.state.tenantInfo.logo | fullPath"
                alt="" />
            </div>
            <p>专业礼品代发平台</p>
          </div>
          <div class="login_text fr" v-if="showLogin">
            还没有账号,
            <router-link to="register">马上注册</router-link>
          </div>
          <div class="login_text fr" v-else>
            已经有账号,
            <router-link to="login">马上登录</router-link>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <div class="footer_wrap">
      <ul class="wrap">
        <li>
          <img src="@/assets/img/bottom1.png" alt="" />
          <div class="info">
            <span class="title">降低成本</span>
            <p class="desc">帮你降低50%发货成本</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/bottom2.png" alt="" />
          <div class="info">
            <span class="title">去除库存</span>
            <p class="desc">回收库存、去礼品库</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/bottom3.png" alt="" />
          <div class="info">
            <span class="title">保证品质</span>
            <p class="desc">注重品质，物廉价美</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/bottom4.png" alt="" />
          <div class="info">
            <span class="title">多种选择</span>
            <p class="desc">低价礼品、配重礼品、高端礼品</p>
          </div>
        </li>
      </ul>
      <div class="copyright">
        <div class="link wrap" v-if="linksList.length > 0">
          <p>友情链接：</p>
          <a target="_blank" :href="item.url" v-for="(item, index) in linksList" :key="index">{{
            item.name
          }}</a>
        </div>
        <div class="jc" v-if="$store.state.tenantInfo.beianhao">
          <img src="@/assets/img/guohui.png" alt="" />
          <a href="https://beian.miit.gov.cn/" target="_blank">{{
            $store.state.tenantInfo.beianhao
          }}</a>
        </div>
        <div class="jc">
          声明:本站产品均为商家辅助使用,禁止使用于违法或侵害他人权益行为,若有立马封号处理,余额不退还,情节严重者,本站积极协助公安机关调查!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeToken } from "../utils/auth";
export default {
  name: "Home",
  data() {
    return {
      showHeader: true,
      showLogin: true,
      linksList: [],
      aboutShow: 1,
      showOtherSite: 1,
      phones: "",
    };
  },
  created() {
    this.getUrl();
    this.phones = localStorage.getItem("phone");

    this.$store.commit("getParams");
    let token = localStorage.getItem("dftToken");
    if (token) {
      this.$store.commit("setIsLogin", true);
      this.getUserInfo();
    } else {
      this.$store.commit("setIsLogin", false);
    }
    if (
      this.$route.name == "Login" ||
      this.$route.name == "ForgetPassword" ||
      this.$route.name == "Register"
    ) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
    if (this.$route.name == "Login") {
      this.showLogin = true;
    } else {
      this.showLogin = false;
    }
    this.getLinks();
    if (window.location.host == "www.fabwang.com") {
      this.aboutShow = 2;
    }
    if (
      "dtk.fabwang.com;bao.hzkem.com;ruguo.fabwang.com;daixia.fabwang.com;cizhuan.fabwang.com;fd.fabwang.com".indexOf(
        window.location.host
      ) == -1
    ) {
      this.showOtherSite = 2;
    }
  },
  watch: {
    $route(to, from) {
      if (
        to.name == "Login" ||
        to.name == "ForgetPassword" ||
        this.$route.name == "Register"
      ) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
      if (this.$route.name == "Login") {
        this.showLogin = true;
      } else {
        this.showLogin = false;
      }
    },
  },
  methods: {
    getUrl() {
      var url = window.location.href;

      var dz_url;
      if (url.indexOf("#") != -1) {
        dz_url = url.split("#")[0];
      }
      var cs;
      if (dz_url) {
        cs = dz_url.split("?")[1];
      }
      var cs_arr;
      if (cs) {
        cs_arr = cs.split("&");
      }
      var cs = {};
      if (cs_arr) {
        for (var i = 0; i < cs_arr.length; i++) {
          cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
        }
      }
      if (cs && cs.inviteCode) {
        localStorage.setItem("inviteCode", cs.inviteCode);
      }
    },
    logout() {
      this.$store.commit("getUserInfo", {});
      this.$store.commit("setIsLogin", false);
      localStorage.removeItem("dftToken");
      localStorage.removeItem("phone");
      removeToken();
      this.$router.push("/login");
    },
    getUserInfo() {
      if (this.phones === undefined || this.phones === "") {
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/user/getUserInfo",
        params: {
          mobile: this.phones,
        },
        success: (res) => {
          if (res) {
            var arr = {};
            arr.info = res;
            if (res.levelId == 0) {
              arr.level = "普通会员";
            } else if (res.levelId == 1) {
              arr.level = "高级会员";
            } else {
              arr.level = "最高会员";
            }

            this.$store.commit("getUserInfo", arr);
          }
        },
      });
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },
    getLinks() {
      this.$request.post({
        url: "/ReportApi/business/basic/getLinks",


        success: (res) => {
          if (res) {
            this.linksList = res;
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  height: 100%;

  .header_wrap {
    min-height: 100%;

    .main {
      padding-bottom: 200px;

      .header {
        .top_header {
          background: #f5f5f5;
          border-bottom: #eaeaea;

          a {
            color: #999;

            &:hover {
              color: #333;
            }
          }

          .wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            color: #999;
            font-size: 12px;
          }

          .top_right {
            li {
              padding: 0 15px;
              border-right: 1px solid #e1e1e1;
              display: inline-block;
              cursor: pointer;
            }
          }
        }

        .center_header {
          height: 125px;

          .logo {
            width: 220px;
            height: 105px;
            margin-top: 10px;
          }

          .nav {
            margin-left: 50px;

            ul {
              float: left;

              width: 610px;
              display: block;
              margin-top: 50px;
              margin-right: 30px;
            }

            li {
              float: left;
              margin: 0 4px;
              padding: 0 4px;
              font-size: 16px;
              color: #999;

              &:hover {
                cursor: pointer;
                color: #ea242f;
              }
            }

            .router-link-exact-active {
              color: #ea242f;
            }
          }

          .head_menu_user {
            margin-top: 50px;

            img {
              margin-right: 10px;
            }

            .info {
              color: #999;
              line-height: 25px;
              font-size: 14px;

              .red {
                color: #ea242f;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-flex;
                max-width: 90px;
              }

              .logout {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .login_header {
        padding-top: 30px;

        .login_img {
          display: flex;
          align-items: center;

          div:hover {
            cursor: pointer;
          }

          p {
            color: #8c8c8c;
            margin-left: 15px;
          }
        }

        .login_text {
          line-height: 130px;
          font-size: 14px;

          a {
            color: #ea242f;
          }
        }
      }
    }
  }

  .footer_wrap {
    height: 200px;
    margin-top: -200px;
    padding-top: 30px;

    ul {
      li {
        display: inline-block;
        width: 270px;
        height: 56.5px;

        img {
          width: 74px;
          margin-top: -7px;
        }

        .info {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;

          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            line-height: 36px;
          }

          .desc {
            font-size: 12px;
            color: #999;
          }
        }

        &:not(:first-child) {
          margin-left: 33px;
        }
      }
    }

    .copyright {
      text-align: center;
      font-size: 12px;
      color: #666;
      margin-top: 48px;
      clear: both;

      .jc {
        line-height: 50px;
      }

      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        p {
          display: flex;
          flex-wrap: wrap;
        }

        a {
          margin-right: 6px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
